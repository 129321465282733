<template>
    <div class="max-w-7xl pt-24 mx-auto sm:px-1 lg:px-8 py-8">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <div class="flex flex-col justify-center w-full p-1" v-else>
            <!-- Header Section -->
            <div class="bg-white rounded-xl shadow-sm p-1">
                <div class="flex flex-col lg:flex-row lg:items-center lg:justify-between md:gap-4">
                    <div class="flex items-center md:space-x-4">
                        <img
                            :src="publication.logo"
                            :alt="publication.name"
                            class="h-12 p-2 w-auto"
                            loading="lazy"
                        />
                        <div>
                            <h1 class="text-2xl font-bold text-gray-900">{{ publication.name }}</h1>
                            <div class="flex flex-col sm:flex-row sm:items-center gap-2 text-sm text-gray-500">
                                <div class="flex items-center gap-1">
                                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                        ></path>
                                    </svg>
                                    <span>Date din: {{ formatDate(firstDataDate) }}</span>
                                </div>
                                <span class="hidden sm:inline">•</span>
                                <div class="flex items-center gap-1">
                                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                        ></path>
                                    </svg>
                                    <span>Actualizat: {{ formatDateTime(dataUpdatedAt) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Data Collection Period Stats -->
                <div class="mt-4 py-4 border-t border-gray-100">
                    <div class="grid grid-cols-3 gap-4">
                        <div class="flex items-center gap-3" v-for="stat in stats" :key="stat.label">
                            <div :class="stat.iconBg" class="p-2 rounded-lg">
                                <i :class="stat.icon"></i>
                            </div>
                            <div>
                                <p class="text-sm font-medium text-gray-500">{{ stat.label }}</p>
                                <p class="text-base font-semibold text-gray-900">{{ stat.value }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-1">
                <div class="flex max-md:flex-wrap mb-8 gap-2">
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-full h-[450px]">
                        <EChartComponent
                            refValue="mostFrequentSources"
                            key="1"
                            v-if="charts.averageReadingTime.series.length"
                            :chart-options="charts.averageReadingTime.options"
                            :chart-series="charts.averageReadingTime.series"
                            type="bar"/>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                </div>
                <div class="flex max-md:flex-wrap mb-8 gap-2">
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-1/2 max-md:w-full h-[450px]">
                        <EChartComponent
                            refValue="mostFrequentSources"
                            key="1"
                            v-if="charts.partyBarChart.series.length"
                            :chart-options="charts.partyBarChart.options"
                            :chart-series="charts.partyBarChart.series"
                            type="bar"/>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-1/2 max-md:w-full h-[450px]">
                        <EChartComponent
                            refValue="mostFrequentSources"
                            key="1"
                            v-if="charts.partyPieChart.series.length"
                            :chart-options="charts.partyPieChart.options"
                            :chart-series="charts.partyPieChart.series"
                            type="bar"/>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-1/2 max-md:w-full h-[450px]">
                        <EChartComponent
                            refValue="mostFrequentSources"
                            key="1"
                            v-if="charts.partyLineChart.series.length"
                            :chart-options="charts.partyLineChart.options"
                            :chart-series="charts.partyLineChart.series"
                            type="bar"/>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                </div>
                <div class="flex max-md:flex-wrap mb-8 gap-2">
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-1/2 max-md:w-full h-[550px]">
                        <EChartComponent
                            refValue="mostFrequentSources"
                            key="1"
                            v-if="charts.personsBarChart.series.length"
                            :chart-options="charts.personsBarChart.options"
                            :chart-series="charts.personsBarChart.series"
                            type="bar"/>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-1/2 max-md:w-full h-[550px]">
                        <EChartComponent
                            refValue="mostFrequentSources"
                            key="1"
                            v-if="charts.personsPieChart.series.length"
                            :chart-options="charts.personsPieChart.options"
                            :chart-series="charts.personsPieChart.series"
                            type="bar"/>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                </div>
                <div class="flex max-md:flex-wrap mb-8 gap-2">
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-1/2 max-md:w-full h-[550px]">
                        <EChartComponent
                            refValue="mostFrequentSources"
                            key="1"
                            v-if="charts.politicalOrientationPieChart.series.length"
                            :chart-options="charts.politicalOrientationPieChart.options"
                            :chart-series="charts.politicalOrientationPieChart.series"
                            type="bar"/>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-1/2 max-md:w-full h-[550px]">
                        <EChartComponent
                            refValue="mostFrequentSources"
                            key="1"
                            v-if="charts.subjectiveObjectivePieChart.series.length"
                            :chart-options="charts.subjectiveObjectivePieChart.options"
                            :chart-series="charts.subjectiveObjectivePieChart.series"
                            type="bar"/>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                </div>
                <div class="flex max-md:flex-wrap mb-8 gap-2">
                    <div class="mb-8 bg-white shadow-lg rounded-lg p-2  w-full h-[550px]">
                        <h2 class="font-bold mb-4 text-center text-lg text-gray-700">Cele mai Menționate Entități</h2>
                        <WordCloud v-if="charts.mostFrequentTags.series.length" :word-data="charts.mostFrequentTags.series"></WordCloud>
                        <LoadingSpinner v-else></LoadingSpinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "../Components/LoadingSpinner.vue";
import WordCloud from "../Components/WordCloud.vue";
import {defineAsyncComponent} from "vue";

const EChartComponent = defineAsyncComponent(() =>
    import(/* webpackChunkName: "echart-component" */ '@/Components/EChartComponent.vue')
);


export default {
    components: {WordCloud, EChartComponent, LoadingSpinner},
    props: {
        seo: Object,
        publication: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            charts: {
                subjectiveObjectivePieChart: {
                    series: [],
                    options: {},
                },
                politicalOrientationPieChart: {
                    series: [],
                    options: {},
                },
                personsPieChart: {
                    series: [],
                    options: {},
                },
                personsBarChart: {
                    series: [],
                    options: {},
                },
                partyBarChart: {
                    series: [],
                    options: {},
                },
                partyPieChart: {
                    series: [],
                    options: {},
                },
                partyLineChart: {
                    series: [],
                    options: {},
                },
                averageReadingTime: {
                    series: [],
                    options: {},
                },
                mostMentionedParties: {
                    series: [],
                    options: {},
                },
                mostMentionedPersons: {
                    series: [],
                    options: {},
                },
                politicalOrientation: {
                    series: [],
                    options: {},
                },
                objectiveSubjective: {
                    series: [],
                    options: {},
                },
                mostFrequentTags: {
                    series: [],
                    options: {},
                }
            },
            loading: true,
            cachedData: null,
            firstDataDate: null,
            dataUpdatedAt: null,
            stats: [],
        };
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const response = await axios.get(`/api/publications/${this.publication.id}`);
                const data = response.data;

                this.cachedData = data.cachedData;
                this.firstDataDate = data.firstDataDate;
                this.dataUpdatedAt = data.dataUpdatedAt;

                this.stats = [
                    {
                        label: "Perioada de colectare",
                        value: this.relativeDate(this.firstDataDate),
                        icon: "fa fa-clock",
                        iconBg: "bg-blue-50 text-blue-500",
                    },
                    {
                        label: "Media zilnică",
                        value: `${this.calculateDailyAverage()} articole`,
                        icon: "fa fa-chart-line",
                        iconBg: "bg-green-50 text-green-500",
                    },
                    {
                        label: "Total Articole",
                        value: `${this.cachedData.totalArticles} articole`,
                        icon: "fa fa-newspaper",
                        iconBg: "bg-main/10 text-main",
                    },
                ];

                this.initAverageReadingTimeChart(this.cachedData.averageReadingTime);
                this.initPartyCharts(this.cachedData.tagData.parties);
                this.initPersonsCharts(this.cachedData.tagData.persons);
                this.initPoliticalOrientationChart(data.politicalOrientationDistribution);
                this.initSubjectiveObjectiveChart(data.subjectiveObjectiveDistribution);
                this.initMostMentionedEntitiesChart(this.cachedData.tagData.tags);
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                this.loading = false;
            }
        },
        initMostMentionedEntitiesChart(data) {
            this.charts.mostFrequentTags.series = data
                .filter((item) => item.count > 20) // Filter items with count > 50
                .map((item) => ({
                    text: item.name,
                    weight: item.count,
                }));
        },
        initSubjectiveObjectiveChart(data) {
            const formattedData = [
                { name: 'Subiectiv', value: data.subiectiv, itemStyle: { color: '#FF5733' } },
                { name: 'Obiectiv', value: data.obiectiv, itemStyle: { color: '#33B5E5' } },
            ];
            this.charts.subjectiveObjectivePieChart = {
                series: [
                    {
                        name: 'Obiectiv vs. Subiectiv',
                        type: 'pie',
                        radius: '70%', // Simple pie chart
                        center: ['50%', '50%'], // Adjust center for better alignment
                        data: formattedData,
                        label: {
                            position: 'outside', // Labels outside the chart
                            formatter: '{b}\n{c} articole\n({d}%)', // Multi-line labels
                        },
                        labelLine: {
                            show: true,
                            length: 10, // Longer connector lines
                            length2: 10,
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '14',
                                fontWeight: 'bold',
                            },
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                            },
                        },
                    },
                ],
                options: {
                    title: {
                        text: 'Obiectiv vs. Subiectiv',
                        left: 'center',
                        top: '5%',
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)',
                    },
                    legend: {
                        orient: 'horizontal', // Horizontal legend below
                        bottom: '0%',
                        data: formattedData.map((item) => item.name),
                    },
                },
            };
        },
        initPoliticalOrientationChart(data) {
            const formattedData = [
                { name: 'Stânga', value: data.stanga, itemStyle: { color: '#ee6666' } },
                { name: 'Dreapta', value: data.dreapta, itemStyle: { color: '#1976D2' } },
                { name: 'Centru', value: data.centru, itemStyle: { color: '#F5F5F5' } },
            ];

            this.charts.politicalOrientationPieChart = {
                series: [
                    {
                        name: 'Orientare Politică',
                        type: 'pie',
                        radius: ['40%', '70%'], // Doughnut-style chart
                        center: ['50%', '50%'],
                        data: formattedData,
                        label: {
                            position: 'outside', // Labels outside the chart
                            formatter: '{b}\n{c} articole\n({d}%)', // Multi-line labels
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 20,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
                options: {
                    title: {
                        text: 'Orientarea Politică a Articolelor',
                        left: 'center',
                        top: '5%',
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)',
                    },
                    legend: {
                        orient: 'horizontal', // Horizontal legend below
                        bottom: '0%',
                        data: formattedData.map((item) => item.name),
                    },
                },
            };
        },
        initPartyCharts(data) {
            // Transform data for chart usage
            const labels = data.map((item) => item.name);
            const values = data.map((item) => item.count);

            this.charts.partyBarChart.series = [
                {
                    name: 'Partide',
                    type: 'bar',
                    data: values,
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0],
                    },
                },
            ];
            // Bar Chart
            this.charts.partyBarChart.options = {
                    title: {
                        text: 'Număr de mențiuni - Partide',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {type: 'shadow'},
                        formatter: '{a} <br/>{b}: {c} mențiuni',
                    },
                    xAxis: {
                        type: 'category',
                        data: labels,
                        axisLabel: {rotate: 45},
                    },
                    yAxis: {type: 'value'},
            };

            // Pie Chart
            this.charts.partyPieChart.series = [
                {
                    name: 'Partide',
                    type: 'pie',
                    radius: ['30%', '70%'],
                    center: ['50%', '45%'],
                    data: data.map((item) => ({name: item.name, value: item.count})),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ];
            this.charts.partyPieChart.options = {
                title: {
                    text: 'Distribuție Partide',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)',
                },
                legend: {
                    orient: 'horizontal',
                    bottom: '0%',
                    data: labels,
                },
            };

            // Line Chart
            this.charts.partyLineChart.series = [
                {
                    name: 'Partide',
                    type: 'line',
                    data: values,
                },
            ];
            this.charts.partyLineChart.options = {
                title: {
                    text: 'Tendințe Partide',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{a} <br/>{b}: {c} mențiuni',
                },
                xAxis: {
                    type: 'category',
                    data: labels,
                },
                yAxis: {type: 'value'},
            };

        },
        initPersonsCharts(persons) {
            const threshold = 40; // Minimum count to display as separate segment
            let groupedData = [];
            let othersCount = 0;

            persons.forEach((item) => {
                if (item.count >= threshold) {
                    groupedData.push({ name: item.name, value: item.count });
                } else {
                    othersCount += item.count;
                }
            });

            if (othersCount > 0) {
                groupedData.push({ name: 'Altele', value: othersCount });
            }

            const labels = groupedData.map((item) => item.name);
            const values = groupedData.map((item) => item.value);

            this.charts.personsBarChart = {
                series: [
                    {
                        name: 'Persoane',
                        type: 'bar',
                        data: values,
                        itemStyle: {
                            borderRadius: [1, 1, 0, 0],
                        },
                    },
                ],
                options: {
                    title: {
                        text: 'Număr de mențiuni - Persoane',
                        left: 'center',
                    },
                    color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { type: 'shadow' },
                        formatter: '{a} <br/>{b}: {c} mențiuni',
                    },
                    xAxis: {
                        type: 'category',
                        data: labels,
                    },
                    yAxis: { type: 'value' },
                },
            };

            this.charts.personsPieChart = {
                series: [
                    {
                        name: 'Persoane',
                        type: 'pie',
                        radius: ['20%', '60%'],
                        center: ['50%', '50%'],
                        data: groupedData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
                options:  {
                title: {
                    text: 'Distribuție Persoane',
                        left: 'center',
                        top: '5%', // Move the title higher to avoid overlap
                },
                tooltip: {
                    trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)',
                }
            },
            };
        },
        initAverageReadingTimeChart(data) {
            // Transform the data to extract labels and values
            let labels = data.map((item) => item.reading_time_range);
            let values = data.map((item) => item.article_count);

            this.charts.averageReadingTime.series = [
                {
                    name: 'Timp de citire',
                    type: 'bar',
                    data: values, // Use article counts as bar heights
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0], // Rounded top corners
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ];

            this.charts.averageReadingTime.options = {
                title: {
                    text: 'Distribuția timpului de citire',
                    subtext: 'Număr de articole',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow', // Highlight bars on hover
                    },
                    formatter: '{a} <br/>{b}: {c} articole',
                },
                xAxis: {
                    type: 'category',
                    data: labels, // Set the categories on the x-axis
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        rotate: 45, // Rotate labels for readability if necessary
                    },
                },
                yAxis: {
                    type: 'value',
                    name: 'Număr de articole',
                },
                legend: {
                    orient: 'horizontal',
                    bottom: '0%',
                    left: 'center',
                },
            };
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString();
        },
        formatDateTime(dateTime) {
            return new Date(dateTime).toLocaleString();
        },
        relativeDate(date) {
            const diff = new Date() - new Date(date);
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            return `${days} zile`;
        },
        calculateDailyAverage() {
            const diffDays = Math.max(1, (new Date() - new Date(this.firstDataDate)) / (1000 * 60 * 60 * 24));
            return (this.cachedData.totalArticles / diffDays).toFixed(1);
        },
    },
    mounted() {
        this.fetchData();
    },
};
</script>
